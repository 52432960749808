<!--
 * @Description: 登录之后跳转的slide homeSlide
 * @Author: zhoucheng
 * @Github: 
 * @Date: 2021-03-30 15:25:44
 * @LastEditors: zhoucheng
-->
<template>
  <div class="example-3d">
    <el-row class="name">
      您好:{{ userName }}
      <img src="@/assets/homeSlide/exit.png"
           alt=""
           @click="handleClickExit"
           class="topimg" />
      <span @click="handleClickExit">退出</span>
    </el-row>
    <img class="titleImg"
         src="@/assets/homeSlide/header.png"
         alt="" />
    <el-row class="divSwiper">
      <ul id="test"
          v-show="showBanner">
        <li class="monolith"><span></span></li>
        <li class="mover"
            v-for="(item, index) in systemManagementList"
            :key="index"
            @click="handleClick(item, index)">
          <template v-if="icos[item.menuId]">
            <span style="color:white">{{ icos[item.menuId].menuName }}</span>
            <div class="imgBox">
              <div class="imgBox_border"></div>
              <img :src="icos[item.menuId].ico"
                   alt="" />
              <div class="imgBox_border"></div>
            </div>
          </template>
        </li>
      </ul>
    </el-row>
  </div>
</template>
<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import "swiper/css/swiper.css";
import { mapGetters } from "vuex";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    //这里存放数据
    return {
      showBanner: false,
      userName: "",
      setSwiper: false,
      // 循环系统列表
      systemManagementList: [],
      // slide属性值
      swiperOption: {
        // effect:'cube',
        // effect: "fade",
        // grabCursor: true,  //夺取光标
        centeredSlides: true, //圆点
        loop: true, //无限循环
      },
      icos: {
        2001: {
          menuName: "车行道停车运营管理",
          ico: require("@/assets/homeSlide/onRoad.png"),
        },
        3001: {
          menuName: "人行道停车运营管理",
          ico: require("@/assets/homeSlide/parkManage.png"),
        },
        4001: {
          menuName: "设备运维管理",
          ico: require("@/assets/homeSlide/manage.png"),
        },
        5001: {
          menuName: "停车用户管理",
          ico: require("@/assets/homeSlide/carUser.png"),
        },
        1001: {
          menuName: "平台系统管理",
          ico: require("@/assets/homeSlide/platformSystem.png"),
        },
        6001: {
          menuName: "客服用户服务管理",
          ico: require("@/assets/homeSlide/userService.png"),
        },
        7001: {
          menuName: "视频监控管理",
          ico: require("@/assets/homeSlide/vedioManage.png"),
        },
        8001: {
          menuName: "财务综合管理",
          ico: require("@/assets/homeSlide/finalManage.png"),
        },
        9001: {
          menuName: "诱导管理",
          ico: require("@/assets/homeSlide/induceManagement.jpg"),
        },
        10001: {
          menuName: "大数据分析",
          ico: require("@/assets/homeSlide/hadoopManagement.png"),
        }
      },
      focusIdx: 0,
    };
  },
  //监听属性 类似于data概念
  computed: {
    ...mapGetters(["refreshPage"]),
    swiper () {
      return this.$refs.mySwiper.swiper;
    },
  },
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.userName = localStorage.getItem("userName");
    this.querySiderListByUser();
    localStorage.setItem('theme', 'dark')
  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
  //方法集合
  methods: {
    init () {
      let that = this;
      // eslint-disable-next-line no-undef
      $(document).ready(() => {
        setTimeout(() => {
          // eslint-disable-next-line no-undef
          $("#test").roundabout(
            {
              minOpacity: -0.2, //最小透明度
              // minScale: 0.5,
              childSelector: ".mover",
              tilt: -2, //上下弧度
              enableDrag: true,
              clickToFocusCallback: function (res) {
                setTimeout(() => {
                  that.focusIdx = res;
                }, 0);
              },
            }, () => {
              this.showBanner = true;
            }
          );
        }, 0);
      });
    },
    // 退出
    handleClickExit () {
      this.$router.push({ path: "/login" });
      localStorage.removeItem("userToken")
      localStorage.removeItem("userName")
    },
    // 侧边栏按照用户查询
    querySiderListByUser () {
      //调接口拿数据
      this.$systemMenuManagement.queryListByUser({}).then((res) => {
        this.systemManagementList = res.resultEntity;
        this.swiperOption = {
          ...this.publicFun.clone(this.swiperOption),
          effect: res.resultEntity.length >= 5 ? "coverflow" : "slide",
        };
        this.setSwiper = true;
        this.init();
      });
    },
    handleClick (item, idx) {
      if (idx == this.focusIdx) {
        localStorage.setItem("subSystem", item.menuId);
        this.$store.dispatch("app/refreshPage", { refreshPage: true });
        this.$router.push({ path: "/layout" });
      }
    },
  },
};
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
.example-3d {
  width: 100%;
  height: 100%;
  background-image: url('../../assets/homeSlide/background.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .name {
    position: absolute;
    height: 30px;
    line-height: 30px;
    color: #ffffff;
    float: right;
    right: 50px;
    top: 40px;
    z-index: 99;
    .topimg {
      vertical-align: middle;
      margin-left: 12px;
    }
  }
  .title {
    width: 100%;
    text-align: center;
    height: 40px;
    font-size: 32px;
  }
  .titleImg {
    margin-top: -15px;
    width: 100%;
  }
  .divSwiper {
    margin-top: -5%;
    margin-left: -33%;
    #test {
      list-style: none;
      padding: 0;
      margin: 0 auto;
      width: 50em;
      height: 26em;
      li {
        width: 700px;
        text-align: center;
        cursor: pointer;
        .imgBox {
          & > div:first-child {
            bottom: -4px;
            border-radius: 10px 10px 0 0;
          }
          & > div:last-child {
            top: -4px;
            border-radius: 0 0 10px 10px;
          }
          .imgBox_border {
            position: relative;
            height: 10px;
            background: linear-gradient(#6caefe, #458ce4);
          }
          & > img {
            width: 100%;
          }
        }
      }
      li.roundabout-in-focus {
        width: 900px !important;
        background: red;
        left: 260px !important;
        top: calc(50% - 10px) !important;
        transition: 0.3s !important;
      }
      li span {
        font-size: 24px;

        font-weight: bold;
        display: block;
      }

      #carbonads-container .carbonad {
        margin: 0 auto;
      }
    }
  }
}
</style>